import './list.scss';
import dataApi from '@/api/system/role.js';
// import menu from '@/api/system/menu.js';

export default {
  name: 'project-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        // pagerCount: 5,
        pageSizes: [3, 10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      tbleOption: {
        align: 'center',
        cellBtn: false,
        addBtn: false,
        menu: false,
        ...this.$config.table,
        column: [
          {
            // ID id
            label: 'ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'id'
          },
          {
            // 操作状态   type
            label: '操作状态',
            prop: 'status'
          },
          {
            // 登陆时间 create_time
            label: '登陆时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      }
    };
  },
  computed: {},
  created () {
    console.log('id', this.$route.params.id);
  },
  mounted () {
    this.getList();
    this.getTree();
  },
  filters: {},
  methods: {
    // 返回
    goBack () {
      console.log('go back');
      this.$router.go(-1);
    },
    getTree () {
      const menu_check_strictly = this.findObject(this.formOp.column, 'menu_check_strictly');
      const dept_check_strictly = this.findObject(this.formOp.column, 'dept_check_strictly');
      console.log('12312312!@#!@#', dept_check_strictly, menu_check_strictly);
      // menu.getList().then((res) => {
      //   console.log('12312312!@#!@#', res);
      // });
    },
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      // console.log('form', form, loading);
      // dict_type: this.$route.params.id,
      const postData = { ...form, dict_type: this.$route.params.id };
      console.log('postData', postData);
      dataApi.add(postData).then(res => {
        console.log(res);
        done(form);
        loading();
      });
      // done(form);
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del({ dict_code: form.dict_code }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update(form).then(res => {
        console.log(res);
        done(form);
        loading();
      });
      // done(form);
    },
    // 获取
    getList () {
      //       page: 1
      // pageSize: 20
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      console.log('接口参数', postData);
      // dataApi.getList(postData).then(res => {
      //   console.log('接口返回', res);
      //   this.tableData = res.list;
      //   this.page.total = res.total;
      // });
    }
  }
};
